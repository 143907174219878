var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sky_wrap"},[_vm._m(0),_c('div',{staticClass:"two_level"},[_c('div',{staticClass:"two_level_wrap"},[_c('div',{staticClass:"two_left"},[_c('div',{staticClass:"tit"},[_vm._v("2020，政府和园区招商面临新挑战！")]),_vm._l((_vm.twoLevelArr),function(item){return _c('div',{key:item.id,staticClass:"left_list"},[_c('div',{staticClass:"list_item"},[_c('span',[_vm._v(_vm._s(item.id))]),_vm._v(_vm._s(item.txt)+" ")])])})],2),_vm._m(1)])]),_vm._m(2),_c('div',{staticClass:"sky_card"},[_c('SkyCard',{attrs:{"skyList":_vm.skyList}})],1),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('CallMe'),_c('MobileCallme')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sky_img"},[_c('div',{staticClass:"img_tit hidden-xs-only"},[_vm._v(" 云招商，生物医药细分领域企业选址专家 ")]),_c('div',{staticClass:"img_desc hidden-xs-only"},[_vm._v(" 基于企业大数据、招商预测模型和细分领域投资选址逻辑"),_c('br'),_vm._v("助力政府园区从传统招商向智能数字化招商转型 ")]),_c('div',{staticClass:"img_tit hidden-sm-and-up"},[_vm._v(" 云招商"),_c('br'),_vm._v("生物医药细分领域企业选址专家 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"two_right"},[_c('img',{attrs:{"src":require("@/assets/img/0146137d.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sky_title_wrap"},[_c('div',{staticClass:"sky_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center"},[_c('div',{staticClass:"sky_title"},[_vm._v("云招商")]),_c('div',{staticClass:"sky_desc"},[_vm._v(" 创新和优化招商引资方式，线上招商一站式解决方案 ")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"three_level"},[_c('div',{staticClass:"three_content"},[_c('p',[_vm._v("45+生物医药细分领域的招商预测模型")]),_c('p',[_vm._v("快速形成专属于您园区的精准画像")]),_c('p',[_vm._v("输出动态更新的目标客户名单")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sky_title_wrap"},[_c('div',{staticClass:"sky_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center"},[_c('div',{staticClass:"sky_title"},[_vm._v("药选址")]),_c('div',{staticClass:"sky_desc"},[_vm._v(" 有效触达超过2万家优质生物医药企业,为园区提供精准线索，提升园区品牌宣传 ")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"four_level"},[_c('div',{staticClass:"four_img hidden-xs-only"},[_c('img',{attrs:{"src":require("@/assets/logo.jpg"),"alt":""}})]),_c('div',{staticClass:"four_right"},[_c('div',{staticClass:"four_tit"},[_vm._v("药选址平台")]),_c('div',{staticClass:"four_zhengfangxing"},[_c('img',{attrs:{"src":require("@/assets/img/zhengfangxing.png"),"alt":""}})]),_c('div',{staticClass:"four_desc"},[_vm._v(" 提供精准、真实、有效的企业选址线索和项目信息，实现需求方和资源方的高效匹配。完善园区多维度信息，搭建在线数字化营销平台。线上对接意向企业。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sky_shows_wrap"},[_c('div',{staticClass:"sky_title_wrap"},[_c('div',{staticClass:"sky_inner"},[_c('div',{staticClass:"inner_left"},[_c('img',{attrs:{"src":require("../../assets/img/tupianright.png"),"alt":""}})]),_c('div',{staticClass:"inner_center"},[_c('div',{staticClass:"sky_title"},[_vm._v("客户见证")]),_c('div',{staticClass:"sky_desc"},[_vm._v("150+政府和生物医药园区见证三支网品质服务")])]),_c('div',{staticClass:"inner_right"},[_c('img',{attrs:{"src":require("../../assets/img/tupianleft.png"),"alt":""}})])])]),_c('div',{staticClass:"sky_shows_imgs"},[_c('img',{staticClass:"hidden-xs-only",attrs:{"src":require("../../assets/img/witness47b5311.png"),"alt":""}}),_c('img',{staticClass:"hidden-sm-and-up",attrs:{"src":require("../../assets/img/shutu.png"),"alt":""}})])])
}]

export { render, staticRenderFns }